/*-------------------------------------------------------------------
	
	
=========================================
  Table of Contents
=========================================

	- Basic
	- Helpers Classes
	- Layouts
	- Header
		- Logo
		- Primary Menu
		- Secondary Nav
		- Page Header
	- Profile
		- Dashboard
		- Cards & Bank Account Page
	- Blog
	- Elements
		- Featured Box
		- Team
		- Accordion & Toggle
		- Tabs
		- Easy Responsive Tab Accordion
		- Custom Background
		- Owl Carousel
		- Brands Grid
		- Banner
		- Simple ul
		- Steps Progress bar
	- Footer
		- Social Icons
		- Back to Top
	- Extra

-------------------------------------------------------------*/
/* =================================== */
/*  Basic Style 
/* =================================== */
body,
html {
  height: 100%;
}

body {
  background: #ffffff;
  color: #4c4d4d;
  font-family: "Rubik", sans-serif;
  font-size: 14px;
  line-height: 22px;
}

/*-------- Preloader --------*/
#preloader {
  position: fixed;
  z-index: 999999999 !important;
  background-color: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
#preloader [data-loader="dual-ring"] {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  display: inline-block;
  content: " ";
  display: block;
  border-radius: 50%;
  border: 5px solid #008046;
  border-color: #008046 transparent #008046 transparent;
  animation: dual-ring 1s linear infinite;
}

@keyframes dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
::selection {
  background: #008046;
  color: #fff;
  text-shadow: none;
}

form {
  padding: 0;
  margin: 0;
  display: inline;
}

img {
  vertical-align: inherit;
}

a,
a:focus {
  color: #008046;
  /* -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; */
}

a:hover,
a:active {
  color: #f91351;
  text-decoration: none;
  /* -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease; */
}

a:focus,
a:active,
.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus,
button:focus,
button:active {
  outline: none;
}

p {
  line-height: 1.9;
}

blockquote {
  border-left: 4px solid #008046;
  padding: 1rem 1.4rem;
  background-color: #f9f9f9;
}

iframe {
  border: 0 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
small {
  color: #2d335b;
  font-family: "Rubik", sans-serif;
}

.lead {
  font-size: 1.22rem;
}

/* =================================== */
/*  Helpers Classes
/* =================================== */
/* Box Shadow */
.shadow-md {
  -webkit-box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 6px #00000029;
}

/* Border Radius */
.rounded-top-0 {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.rounded-left-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.rounded-right-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

/* Text Size */
.text-0 {
  font-size: 11px !important;
  font-size: 0.6875rem !important;
}

.text-1 {
  font-size: 12px !important;
  font-size: 0.75rem !important;
}

.text-2 {
  font-size: 14px !important;
  font-size: 0.875rem !important;
}

.text-3 {
  font-size: 16px !important;
  font-size: 1rem !important;
}

.text-4 {
  font-size: 18px !important;
  font-size: 1.125rem !important;
}

.text-5 {
  font-size: 21px !important;
  font-size: 1.3125rem !important;
}

.text-6 {
  font-size: 24px !important;
  font-size: 1.5rem !important;
}

.text-7 {
  font-size: 28px !important;
  font-size: 1.75rem !important;
}

.text-8 {
  font-size: 32px !important;
  font-size: 2rem !important;
}

.text-9 {
  font-size: 36px !important;
  font-size: 2.25rem !important;
}

.text-10 {
  font-size: 40px !important;
  font-size: 2.5rem !important;
}

.text-11 {
  font-size: 2.75rem !important;
}

.asideDesktopView {
  min-height: 82vh;
}

@media (max-width: 1200px) {
  .text-11 {
    font-size: calc(1.4rem + 1.8vw) !important;
  }
}

.text-12 {
  font-size: 3rem !important;
}
@media (max-width: 1200px) {
  .text-12 {
    font-size: calc(1.425rem + 2.1vw) !important;
  }
}

.text-13 {
  font-size: 3.25rem !important;
}
@media (max-width: 1200px) {
  .text-13 {
    font-size: calc(1.45rem + 2.4vw) !important;
  }
}

.text-14 {
  font-size: 3.5rem !important;
}
@media (max-width: 1200px) {
  .text-14 {
    font-size: calc(1.475rem + 2.7vw) !important;
  }
}

.text-15 {
  font-size: 3.75rem !important;
}
@media (max-width: 1200px) {
  .text-15 {
    font-size: calc(1.5rem + 3vw) !important;
  }
}

.text-16 {
  font-size: 4rem !important;
}
@media (max-width: 1200px) {
  .text-16 {
    font-size: calc(1.525rem + 3.3vw) !important;
  }
}

.text-17 {
  font-size: 4.5rem !important;
}
@media (max-width: 1200px) {
  .text-17 {
    font-size: calc(1.575rem + 3.9vw) !important;
  }
}

.text-18 {
  font-size: 5rem !important;
}
@media (max-width: 1200px) {
  .text-18 {
    font-size: calc(1.625rem + 4.5vw) !important;
  }
}

.text-19 {
  font-size: 5.25rem !important;
}
@media (max-width: 1200px) {
  .text-19 {
    font-size: calc(1.65rem + 4.8vw) !important;
  }
}

.text-20 {
  font-size: 5.75rem !important;
}
@media (max-width: 1200px) {
  .text-20 {
    font-size: calc(1.7rem + 5.4vw) !important;
  }
}

.text-21 {
  font-size: 6.5rem !important;
}
@media (max-width: 1200px) {
  .text-21 {
    font-size: calc(1.775rem + 6.3vw) !important;
  }
}

.text-22 {
  font-size: 7rem !important;
}
@media (max-width: 1200px) {
  .text-22 {
    font-size: calc(1.825rem + 6.9vw) !important;
  }
}

.text-23 {
  font-size: 7.75rem !important;
}
@media (max-width: 1200px) {
  .text-23 {
    font-size: calc(1.9rem + 7.8vw) !important;
  }
}

.text-24 {
  font-size: 8.25rem !important;
}
@media (max-width: 1200px) {
  .text-24 {
    font-size: calc(1.95rem + 8.4vw) !important;
  }
}

.text-25 {
  font-size: 9rem !important;
}
@media (max-width: 1200px) {
  .text-25 {
    font-size: calc(2.025rem + 9.3vw) !important;
  }
}

.text-11,
.text-12,
.text-13,
.text-14,
.text-15,
.text-16,
.text-17,
.text-18,
.text-19,
.text-20,
.text-21,
.text-22,
.text-23,
.text-24,
.text-25 {
  line-height: 1.3;
}

/* Line height */
.line-height-07 {
  line-height: 0.7 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.line-height-2 {
  line-height: 1.2 !important;
}

.line-height-3 {
  line-height: 1.4 !important;
}

.line-height-4 {
  line-height: 1.6 !important;
}

.line-height-5 {
  line-height: 1.8 !important;
}

/* Font Weight */
.font-weight-100 {
  font-weight: 100 !important;
}

.font-weight-200 {
  font-weight: 200 !important;
}

.font-weight-300 {
  font-weight: 300 !important;
}

.font-weight-400 {
  font-weight: 400 !important;
}

.font-weight-500 {
  font-weight: 500 !important;
}

.font-weight-600 {
  font-weight: 600 !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.font-weight-800 {
  font-weight: 800 !important;
}

.font-weight-900 {
  font-weight: 900 !important;
}

/* Opacity */
.opacity-0 {
  opacity: 0;
}

.opacity-1 {
  opacity: 0.1;
}

.opacity-2 {
  opacity: 0.2;
}

.opacity-3 {
  opacity: 0.3;
}

.opacity-4 {
  opacity: 0.4;
}

.opacity-5 {
  opacity: 0.5;
}

.opacity-6 {
  opacity: 0.6;
}

.opacity-7 {
  opacity: 0.7;
}

.opacity-8 {
  opacity: 0.8;
}

.opacity-9 {
  opacity: 0.9;
}

.opacity-10 {
  opacity: 1;
}

/* Background light */
.bg-light-1 {
  background-color: #f9f9fb !important;
}

.bg-light-2 {
  background-color: #f8f8fa !important;
}

.bg-light-3 {
  background-color: #f5f5f5 !important;
}

.bg-light-4 {
  background-color: #eff0f2 !important;
}

.bg-light-5 {
  background-color: #ececec !important;
}

/* Background Dark */
.bg-dark {
  background-color: #111418 !important;
}

.bg-dark-1 {
  background-color: #191f24 !important;
}

.bg-dark-2 {
  background-color: #232a31 !important;
}

.bg-dark-3 {
  background-color: #2b343c !important;
}

.bg-dark-4 {
  background-color: #38434f !important;
}

.bg-dark-5 {
  background-color: #435161 !important;
}

hr {
  border-top: 1px solid rgba(16, 85, 96, 0.1);
}

/* =================================== */
/*  Layouts
/* =================================== */
#main-wrapper {
  background: #ffffff;
}
#main-wrapper.boxed {
  max-width: 1200px;
  margin: 0 auto;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.section {
  position: relative;
  padding: 104px 0;
  padding: 6.5rem 0;
}

@media (max-width: 767.98px) {
  .section {
    padding: 3.5rem 0;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1170px !important;
  }
}
@media (max-width: 768px) {
  .container {
    margin: 0 !important;
    padding: 0 !important;
  }
}
/*== Fullscreen Height when use Transparnt Header ==*/
.fullscreen {
  min-height: 100vh !important;
}

/*== Fullscreen Height when use default Header ==*/
.fullscreen-with-header {
  min-height: calc(100vh - 80px) !important;
}

/* =================================== */
/*  Header
/* =================================== */
#header {
  background-color: #34495e;
  padding-right: 30px;
  /* background: #fff;
   border-bottom: 1px solid #efefef; */
}
#header .navbar {
  padding: 0px;
}
#header.bg-transparent {
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: none;
  border-bottom: 1px solid rgba(250, 250, 250, 0.3);
}
#header .logo {
  position: relative;
  float: left;
  margin-right: 15px;
  padding: 15px 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
#header .header-row {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  max-height: 100%;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
#header .header-column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
#header .header-column .header-row {
  -webkit-box-pack: inherit;
  -ms-flex-pack: inherit;
  justify-content: inherit;
}

.navbar-light .navbar-nav .active > .nav-link {
  color: #0c2f55;
}
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show {
  color: #0c2f55;
}
.navbar-light .navbar-nav .show > .nav-link {
  color: #0c2f55;
}

.primary-menu,
.login-signup {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  height: auto !important;
  -webkit-box-ordinal-group: 0;
  -ms-flex-item-align: stretch;
  align-self: stretch;
}
.primary-menu.navbar,
.login-signup.navbar {
  position: inherit;
}
.primary-menu ul.navbar-nav > li,
.login-signup ul.navbar-nav > li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
}
.primary-menu ul.navbar-nav > li > a:not(.btn),
.login-signup ul.navbar-nav > li > a:not(.btn) {
  height: 80px;
  padding-left: 0.85em;
  padding-right: 0.85em;
  color: #4c4d4d;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  position: relative;
  text-transform: uppercase;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.primary-menu ul.navbar-nav > li:hover > a:not(.btn),
.primary-menu ul.navbar-nav > li.active > a:not(.btn),
.login-signup ul.navbar-nav > li:hover > a:not(.btn),
.login-signup ul.navbar-nav > li.active > a:not(.btn) {
  color: #008046;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn),
.login-signup ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
  padding: 8px 0px;
  background-color: transparent;
  color: #777;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.primary-menu
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li
  > a:not(.btn)
  > i:not(.arrow),
.login-signup
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li
  > a:not(.btn)
  > i:not(.arrow) {
  font-size: 0.875rem;
  width: 18px;
  text-align: center;
  margin-right: 7px;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a:not(.btn),
.login-signup
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li:hover
  > a:not(.btn) {
  color: #008046;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.primary-menu ul.navbar-nav > li.dropdown:hover > a:after,
.login-signup ul.navbar-nav > li.dropdown:hover > a:after {
  clear: both;
  content: " ";
  display: block;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
  position: absolute;
  border-width: 0px 7px 6px 7px;
  bottom: 0px;
  left: 50%;
  margin: 0 0 0 -5px;
  z-index: 1022;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu,
.login-signup ul.navbar-nav > li.dropdown .dropdown-menu {
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
  border: 0px none;
  padding: 10px 15px;
  min-width: 230px;
  margin: 0;
  font-size: 14px;
  font-size: 0.875rem;
  z-index: 1021;
}
.primary-menu ul.navbar-nav > li.dropdown.language .dropdown-menu,
.login-signup ul.navbar-nav > li.dropdown.language .dropdown-menu {
  min-width: 140px;
}
.primary-menu ul.navbar-nav > li.dropdown.notifications .dropdown-menu,
.login-signup ul.navbar-nav > li.dropdown.notifications .dropdown-menu {
  width: 265px;
}
.primary-menu
  ul.navbar-nav
  > li.dropdown.notifications
  .dropdown-menu
  li
  > a:not(.btn),
.login-signup
  ul.navbar-nav
  > li.dropdown.notifications
  .dropdown-menu
  li
  > a:not(.btn) {
  white-space: normal;
  padding-left: 24px;
  position: relative;
}
.primary-menu
  ul.navbar-nav
  > li.dropdown.notifications
  .dropdown-menu
  li
  > a:not(.btn)
  > i:not(.arrow),
.login-signup
  ul.navbar-nav
  > li.dropdown.notifications
  .dropdown-menu
  li
  > a:not(.btn)
  > i:not(.arrow) {
  position: absolute;
  top: 12px;
  left: 0px;
}
.primary-menu .dropdown-menu-right,
.login-signup .dropdown-menu-right {
  left: auto !important;
  right: 100% !important;
}
.primary-menu ul.navbar-nav > li.dropdown-mega,
.login-signup ul.navbar-nav > li.dropdown-mega {
  position: static;
}
.primary-menu ul.navbar-nav > li.dropdown-mega > .dropdown-menu,
.login-signup ul.navbar-nav > li.dropdown-mega > .dropdown-menu {
  width: 100%;
  padding: 20px 20px;
  margin-left: 0px !important;
}
.primary-menu
  ul.navbar-nav
  > li.dropdown-mega
  .dropdown-mega-content
  > .row
  > div,
.login-signup
  ul.navbar-nav
  > li.dropdown-mega
  .dropdown-mega-content
  > .row
  > div {
  padding: 5px 5px 5px 20px;
  border-right: 1px solid #eee;
}
.primary-menu
  ul.navbar-nav
  > li.dropdown-mega
  .dropdown-mega-content
  > .row
  > div:last-child,
.login-signup
  ul.navbar-nav
  > li.dropdown-mega
  .dropdown-mega-content
  > .row
  > div:last-child {
  border-right: 0;
}
.primary-menu ul.navbar-nav > li.dropdown-mega .sub-title,
.login-signup ul.navbar-nav > li.dropdown-mega .sub-title {
  display: block;
  font-size: 16px;
  margin-top: 1rem;
  padding-bottom: 5px;
}
.primary-menu ul.navbar-nav > li.dropdown-mega .dropdown-mega-submenu,
.login-signup ul.navbar-nav > li.dropdown-mega .dropdown-mega-submenu {
  list-style-type: none;
  padding-left: 0px;
}
.primary-menu ul.navbar-nav > li a.btn,
.login-signup ul.navbar-nav > li a.btn {
  font-size: 14px;
  padding: 0.65rem 2rem;
  text-transform: uppercase;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu,
.login-signup ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
  left: 100%;
  margin-top: -40px;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-toggle:after,
.login-signup
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-toggle:after {
  border-top: 0.4em solid transparent;
  border-right: 0;
  border-bottom: 0.4em solid transparent;
  border-left: 0.4em solid;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-toggle .arrow,
.login-signup ul.navbar-nav > li.dropdown .dropdown-toggle .arrow {
  position: absolute;
  min-width: 30px;
  height: 100%;
  right: 0px;
  top: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.primary-menu ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after,
.login-signup ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 50%;
  border-color: #000;
  border-top: 1px solid;
  border-right: 1px solid;
  width: 6px;
  height: 6px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.primary-menu
  ul.navbar-nav
  > li.dropdown
  .dropdown-toggle
  .arrow.arrow-right:after,
.login-signup
  ul.navbar-nav
  > li.dropdown
  .dropdown-toggle
  .arrow.arrow-right:after {
  -webkit-transform: translate(-50%, -50%) rotate(225deg);
  transform: translate(-50%, -50%) rotate(225deg);
}
.primary-menu .dropdown-toggle:after,
.login-signup .dropdown-toggle:after {
  content: none;
}

.dropdown-menu {
  -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
  border: 0px none;
  font-size: 14px;
  font-size: 0.875rem;
}

.header-text-light .navbar-toggler span {
  background: #fff;
}

.header-text-light .login-signup ul.navbar-nav > li > a:not(.btn) {
  color: rgba(250, 250, 250, 0.85);
}
.header-text-light .login-signup ul.navbar-nav > li:hover > a:not(.btn),
.header-text-light .login-signup ul.navbar-nav > li.active > a:not(.btn) {
  color: #fff;
}

@media (min-width: 992px) {
  .header-text-light .primary-menu ul.navbar-nav > li > a:not(.btn) {
    color: rgba(250, 250, 250, 0.85);
  }
  .header-text-light .primary-menu ul.navbar-nav > li:hover > a:not(.btn),
  .header-text-light .primary-menu ul.navbar-nav > li.active > a:not(.btn) {
    color: #fff;
  }
}
.primary-menu.navbar-dropdown-dark ul.navbar-nav > li.dropdown .dropdown-menu,
.login-signup.navbar-dropdown-dark ul.navbar-nav > li.dropdown .dropdown-menu {
  background-color: #252a2c;
  color: #fff;
}
.primary-menu.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-menu,
.login-signup.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-menu {
  background-color: #272c2e;
}
.primary-menu.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-divider,
.login-signup.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-divider {
  border-color: rgba(250, 250, 250, 0.2);
}
.primary-menu.navbar-dropdown-dark ul.navbar-nav > li.dropdown:hover > a:after,
.login-signup.navbar-dropdown-dark ul.navbar-nav > li.dropdown:hover > a:after {
  border-color: transparent transparent #252a2c transparent;
}
.primary-menu.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li
  > a:not(.btn),
.login-signup.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li
  > a:not(.btn) {
  color: #a3a2a2;
}
.primary-menu.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li:hover
  > a:not(.btn),
.login-signup.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li:hover
  > a:not(.btn) {
  color: #fff;
}
.primary-menu.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown-mega
  .dropdown-mega-content
  > .row
  > div,
.login-signup.navbar-dropdown-dark
  ul.navbar-nav
  > li.dropdown-mega
  .dropdown-mega-content
  > .row
  > div {
  border-color: #3a3a3a;
}
.primary-menu.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu,
.login-signup.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu {
  background-color: #008046;
  color: #fff;
}
.primary-menu.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-menu,
.login-signup.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-menu {
  background-color: #008046;
}
.primary-menu.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-divider,
.login-signup.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  .dropdown-divider {
  border-color: rgba(250, 250, 250, 0.3);
}
.primary-menu.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown:hover
  > a:after,
.login-signup.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown:hover
  > a:after {
  border-color: transparent transparent #008046 transparent;
}
.primary-menu.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li
  > a:not(.btn),
.login-signup.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li
  > a:not(.btn) {
  color: rgba(250, 250, 250, 0.95);
}
.primary-menu.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li:hover
  > a:not(.btn),
.login-signup.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown
  .dropdown-menu
  li:hover
  > a:not(.btn) {
  color: #fff;
}
.primary-menu.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown-mega
  .dropdown-mega-content
  > .row
  > div,
.login-signup.navbar-dropdown-primary
  ul.navbar-nav
  > li.dropdown-mega
  .dropdown-mega-content
  > .row
  > div {
  border-color: rgba(250, 250, 250, 0.2);
}

@media (max-width: 991.98px) {
  #header .navbar-dropdown-dark.primary-menu:before,
  .primary-menu.navbar-dropdown-dark
    ul.navbar-nav
    > li.dropdown
    .dropdown-menu
    .dropdown-menu {
    background-color: #252a2c;
  }

  #header .navbar-dropdown-primary.primary-menu:before {
    background-color: #008046;
  }

  .primary-menu.navbar-dropdown-primary
    ul.navbar-nav
    > li.dropdown
    .dropdown-menu
    .dropdown-menu {
    background-color: #008046;
  }
  .primary-menu.navbar-dropdown-dark ul.navbar-nav li {
    border-color: #444;
  }
  .primary-menu.navbar-dropdown-dark ul.navbar-nav > li > a {
    color: #a3a2a2;
  }
  .primary-menu.navbar-dropdown-dark ul.navbar-nav > li:hover > a {
    color: #fff;
  }
  .primary-menu.navbar-dropdown-primary ul.navbar-nav li {
    border-color: rgba(250, 250, 250, 0.2);
  }
  .primary-menu.navbar-dropdown-primary ul.navbar-nav > li > a {
    color: rgba(250, 250, 250, 0.8);
  }
  .primary-menu.navbar-dropdown-primary ul.navbar-nav > li:hover > a {
    color: #fff;
  }
}
@media (min-width: 992px) {
  .navbar-toggler {
    display: none;
  }

  .primary-menu ul.navbar-nav > li + li {
    margin-left: 2px;
  }
  .primary-menu
    ul.navbar-nav
    > li.dropdown
    .dropdown-menu
    li:hover
    > a:not(.btn) {
    margin-left: 5px;
  }
  .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li:hover > a .arrow {
    right: -3px;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .primary-menu ul.navbar-nav > li.dropdown > .dropdown-toggle {
    padding-right: 1.6rem;
  }
  .primary-menu ul.navbar-nav > li.dropdown > .dropdown-toggle .arrow:after {
    -webkit-transform: translate(-60%, -70%) rotate(135deg);
    transform: translate(-60%, -50%) rotate(135deg);
    width: 7px;
    height: 7px;
    top: calc(50% - 2.5px);
  }
  .primary-menu ul.navbar-nav > li.dropdown-mega .sub-title:first-child {
    margin-top: 0px;
  }
}
.login-signup
  ul.navbar-nav
  > li.dropdown:not(.notifications)
  .dropdown-menu
  li:hover
  > a:not(.btn) {
  margin-left: 5px;
}
.login-signup
  ul.navbar-nav
  > li.dropdown:not(.notifications)
  .dropdown-menu
  li:hover
  > a
  .arrow {
  right: -3px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.login-signup
  ul.navbar-nav
  > li.dropdown:not(.notifications)
  > .dropdown-toggle {
  padding-right: 1.6rem;
}
.login-signup
  ul.navbar-nav
  > li.dropdown:not(.notifications)
  > .dropdown-toggle
  .arrow:after {
  -webkit-transform: translate(-60%, -70%) rotate(135deg);
  transform: translate(-60%, -50%) rotate(135deg);
  width: 7px;
  height: 7px;
  top: calc(50% - 2.5px);
}
.login-signup
  ul.navbar-nav
  > li.dropdown.notifications
  > .dropdown-toggle
  .arrow,
.login-signup ul.navbar-nav > li.dropdown.profile > .dropdown-toggle .arrow {
  display: none;
}
.login-signup ul.navbar-nav > li.dropdown.notifications.notifications .count,
.login-signup ul.navbar-nav > li.dropdown.profile.notifications .count {
  -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  position: relative;
  top: -8px;
  left: 0;
  font-size: 11px;
  background-color: #dc3545;
  border-radius: 50px;
  height: 16px;
  line-height: 16px;
  color: #fff;
  min-width: 16px;
  text-align: center;
  padding: 0 5px;
  display: inline-block;
  vertical-align: top;
  margin-left: -6px;
  margin-right: -5px;
}
.login-signup ul.navbar-nav > li.dropdown-mega .sub-title:first-child {
  margin-top: 0px;
}

@media (max-width: 991.98px) {
  /* Mobile Menu Button */
  .navbar-toggler {
    width: 25px;
    height: 30px;
    padding: 10px;
    margin: 18px 10px;
    position: relative;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    display: block;
  }
  .navbar-toggler span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: #3c3636;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }
  .navbar-toggler span:nth-child(1) {
    top: 6px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  .navbar-toggler span:nth-child(2) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  .navbar-toggler span:nth-child(3) {
    top: 18px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }
  .navbar-toggler.open span:nth-child(1) {
    top: 5px;
    left: 4px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .navbar-toggler.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
  }
  .navbar-toggler.open span:nth-child(3) {
    top: 21px;
    left: 4px;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }

  #header .primary-menu {
    position: absolute;
    top: 99%;
    right: 0;
    left: 0;
    background: transparent;
    margin-top: 0px;
    z-index: 1000;
  }
  #header .primary-menu:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    width: 100vw;
    height: 100%;
    background: #fff;
    z-index: -1;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  }
  #header .primary-menu > div {
    overflow: hidden;
    overflow-y: auto;
    max-height: 65vh;
    margin: 18px 0;
  }

  .primary-menu ul.navbar-nav li {
    display: block;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 0;
  }
  .primary-menu ul.navbar-nav li:last-child {
    border: none;
  }
  .primary-menu
    ul.navbar-nav
    li.dropdown
    > .dropdown-toggle
    > .arrow.open:after {
    -webkit-transform: translate(-50%, -50%) rotate(-45deg);
    transform: translate(-50%, -50%) rotate(-45deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .primary-menu ul.navbar-nav > li > a:not(.btn) {
    height: auto;
    padding: 8px 0;
    position: relative;
  }
  .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu li > a:not(.btn) {
    padding: 8px 0;
    position: relative;
  }
  .primary-menu ul.navbar-nav > li.dropdown:hover > a:after {
    content: none;
  }
  .primary-menu ul.navbar-nav > li.dropdown .dropdown-toggle .arrow:after {
    -webkit-transform: translate(-50%, -50%) rotate(134deg);
    transform: translate(-50%, -50%) rotate(134deg);
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu {
    margin: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border: none;
    padding: 0px 0px 0px 15px;
  }
  .primary-menu ul.navbar-nav > li.dropdown .dropdown-menu .dropdown-menu {
    margin: 0;
  }
  .primary-menu
    ul.navbar-nav
    > li.dropdown-mega
    .dropdown-mega-content
    > .row
    > div {
    padding: 0px 15px;
  }
}
@media (max-width: 767.98px) {
  .login-signup ul.navbar-nav > li a.btn {
    padding: 0.65rem 0.8rem;
  }

  #header .logo {
    margin-right: 0.25rem;
  }
}
@media (max-width: 380px) {
  #header .logo img {
    max-width: 140px;
  }
}
/* Secondary Nav */
.secondary-nav.nav {
  padding-left: 8px;
}
.secondary-nav.nav .nav-link {
  text-align: center;
  font-size: 16px;
  padding: 1rem 20px;
  white-space: nowrap;
  color: rgba(250, 250, 250, 0.9);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.secondary-nav.nav .nav-link:hover {
  color: #fafafa;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.secondary-nav.nav .nav-link span {
  display: block;
  font-size: 30px;
  margin-bottom: 5px;
}
.secondary-nav.nav .nav-item .nav-link.active {
  background: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.secondary-nav.nav.alternate .nav-link {
  color: rgba(0, 0, 0, 0.6);
}
.secondary-nav.nav.alternate .nav-link:hover {
  color: black;
}
.secondary-nav.nav.alternate .nav-item .nav-link.active {
  background-color: transparent;
  color: #1e1d1c;
  border-bottom: 3px solid #008046;
}

@media (max-width: 1199.98px) {
  .secondary-nav.nav {
    flex-wrap: nowrap;
    overflow: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
  }
}
/* Page Header */
.page-header {
  margin: 0 0 30px 0;
  padding: 30px 0;
}
.page-header h1 {
  font-weight: normal;
  font-size: 30px;
  margin: 0;
  padding: 5px 0;
}
.page-header .breadcrumb {
  background: none;
  margin: 0 0 8px 2px;
  padding: 0;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.page-header .breadcrumb > li {
  display: inline-block;
  font-size: 0.85em;
  text-shadow: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.page-header .breadcrumb > li + li:before {
  color: inherit;
  opacity: 0.7;
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  padding: 0 7px 0 5px;
  font-weight: 900;
}
.page-header .breadcrumb > li a {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.page-header .breadcrumb > li a:hover {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.page-header.page-header-text-light {
  color: #fff;
}
.page-header.page-header-text-light h1 {
  color: #fff;
}
.page-header.page-header-text-light .breadcrumb > li {
  color: rgba(250, 250, 250, 0.8);
}
.page-header.page-header-text-light .breadcrumb > li a {
  color: rgba(250, 250, 250, 0.8);
}
.page-header.page-header-text-light .breadcrumb > li a:hover {
  color: #fff;
}
.page-header.page-header-text-dark h1 {
  color: #1e1d1c;
}
.page-header.page-header-text-dark .breadcrumb > li {
  color: #707070;
}
.page-header.page-header-text-dark .breadcrumb > li a {
  color: #707070;
}
.page-header.page-header-text-dark .breadcrumb > li a:hover {
  color: #008046;
}

/* =================================== */
/*  Profile
/* =================================== */
/* Dashboard */
.profile-thumb {
  position: relative;
  width: 100px;
  height: 100px;
  display: inline-block;
}
.profile-thumb .profile-thumb-edit {
  font-size: 16px;
  width: 37px;
  height: 37px;
  border-radius: 100%;
  position: absolute;
  overflow: hidden;
  bottom: 0px;
  right: 0;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.profile-thumb .profile-thumb-edit .custom-file-input {
  cursor: pointer;
}

input[type="file"]::-webkit-file-upload-button {
  border: none !important;
  background-color: transparent;
}

input[type="file"]::-webkit-file-upload-button:hover {
  border: none !important;
}

input[type="file"]::file-selector-button {
  border: none !important;
}

input[type="file"]::file-selector-button:hover {
  border: none !important;
}
input[type="file"]::file-selector-button::selection {
  border: none !important;
}
input[type="file"]::file-selector-button:focus {
  border: none !important;
}

.profile-completeness .border {
  border: 1px solid #ecf1f2 !important;
}

.transaction-title {
  background-color: #f1f5f6;
  border-top: 1px solid #e9eff0;
  border-bottom: 1px solid #e9eff0;
}

.transaction-item {
  border-bottom: 1px solid #e9eff0;
}
.transaction-item:hover {
  background-color: #f4f7f8;
  cursor: pointer;
}

.transaction-details {
  max-width: 620px !important;
}
.transaction-details .modal-content {
  border: none !important;
}
.transaction-details .modal-body {
  padding: 0px !important;
}

/* Notifications Page */
.notifications-list .notifications-item {
  border-bottom: 1px solid #e9eff0;
  border-left: 3px solid transparent;
}
.notifications-list .notifications-item .icon-bell {
  color: #8e9a9d;
}
.notifications-list .notifications-item h4 {
  font-weight: normal;
  color: inherit;
}
.notifications-list .notifications-item:hover {
  background-color: #f4f7f8;
  cursor: pointer;
}
.notifications-list .notifications-item.unread {
  border-left: 3px solid #008046;
}
.notifications-list .notifications-item.unread .icon-bell {
  color: #008046;
}
.notifications-list .notifications-item.unread h4 {
  font-weight: 500;
  color: #1e1d1c;
}

/* Cards & Bank Accounts*/
.account-card {
  position: relative;
  background: -webkit-linear-gradient(135deg, #6c6c6b, #9e9e9c);
  background: -moz-linear-gradient(135deg, #6c6c6b, #9e9e9c);
  background: -o-linear-gradient(135deg, #6c6c6b, #9e9e9c);
  background: -ms-linear-gradient(135deg, #6c6c6b, #9e9e9c);
  background: linear-gradient(-45deg, #6c6c6b, #9e9e9c);
}
.account-card.account-card-primary {
  background: -webkit-linear-gradient(135deg, #0f5e9d, #418fce);
  background: -moz-linear-gradient(135deg, #0f5e9d, #418fce);
  background: -o-linear-gradient(135deg, #0f5e9d, #418fce);
  background: -ms-linear-gradient(135deg, #0f5e9d, #418fce);
  background: linear-gradient(-45deg, #0f5e9d, #418fce);
}
.account-card .account-card-expire {
  font-size: 8px;
  line-height: 10px;
}
.account-card .account-card-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.85);
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: -ms-flexbox;
  display: flex;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.account-card:hover .account-card-overlay {
  opacity: 1;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.account-card-new {
  background: #f1f5f6;
  border: 1px solid #ebebeb;
}

.account-card .border-left,
.account-card .border-right {
  border-color: rgba(250, 250, 250, 0.1) !important;
}

/* =================================== */
/*  Blog Page
/* =================================== */
/* List Item */
.list-item {
  list-style: none;
  padding: 0;
  margin-bottom: 0;
}
.list-item > li {
  display: block;
  position: relative;
}
.list-item li a {
  padding: 7px 0px 7px 15px;
  color: #4c4d4d;
  display: block;
}
.list-item li a:after {
  content: " ";
  position: absolute;
  top: 50%;
  left: 0px;
  border-color: #000;
  border-top: 2px solid;
  border-right: 2px solid;
  width: 7px;
  height: 7px;
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.list-item a:hover {
  color: #008046;
}
.list-item li a span {
  float: right;
}

/* Tags */
.tags {
  margin-bottom: 10px;
}
.tags a {
  background: #f4f5f4;
  color: #4c4d4d;
  padding: 8px 12px;
  border-radius: 3px;
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 3px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.tags a:hover {
  background: #008046;
  color: #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Side Post */
.side-post .item-post + .item-post {
  margin-top: 15px;
}
.side-post .item-post:after {
  display: block;
  clear: both;
  content: "";
}
.side-post .item-post .img-thumb {
  float: left;
  margin-right: 12px;
}
.side-post .item-post .caption {
  overflow: hidden;
}
.side-post .item-post .caption a {
  color: #1e1d1c;
  display: block;
  margin-top: -3px;
  margin-bottom: 3px;
}
.side-post .item-post .caption a:hover {
  color: #008046;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.side-post .item-post .caption .date-post {
  color: #6c757d;
  font-size: 13px;
  margin-bottom: 0px;
}

/* Post */
.blog-post .title-blog {
  margin-bottom: 1rem;
}
.blog-post .title-blog a {
  color: #1e1d1c;
}
.blog-post .title-blog a:hover {
  color: #008046;
}
.blog-post .meta-blog {
  padding-left: 0px;
}
.blog-post .meta-blog li {
  list-style-type: none;
  display: inline-block;
  margin-right: 12px;
  -ms-flex-align: center;
  align-items: center;
}
.blog-post .meta-blog li i {
  font-size: 16px;
  color: #008046;
  margin-right: 5px;
}
.blog-post .meta-blog li a {
  color: #4c4d4d;
}
.blog-post .meta-blog li a:hover {
  color: #008046;
}

/* Post Comment */
.post-comment ul {
  padding: 0px;
  list-style-type: none;
}
.post-comment ul li {
  border-top: 1px solid rgba(16, 85, 96, 0.1);
  padding-top: 1.3rem;
  margin-top: 1rem;
}
.post-comment ul ul {
  margin-left: 2.5rem;
}
.post-comment > ul > li:first-child {
  border-top: none;
  padding-top: 0;
  margin-top: 0;
}

@media (max-width: 767.98px) {
  .post-comment ul ul {
    margin-left: 1.5rem;
  }
}
/* =================================== */
/*  Elements
/* =================================== */
/* Featured Box */
.featured-box {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.featured-box h3,
.featured-box h4 {
  font-size: 1.25rem;
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 500;
}
.featured-box:not(.style-5) .featured-box-icon {
  display: inline-block;
  font-size: 40px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  width: 45px;
  margin-top: 0;
  margin-bottom: 20px;
  color: #4c4d4d;
  border-radius: 0;
}
.featured-box.style-1,
.featured-box.style-2,
.featured-box.style-3 {
  padding-left: 50px;
  padding-top: 8px;
}
.featured-box.style-1 .featured-box-icon,
.featured-box.style-2 .featured-box-icon,
.featured-box.style-3 .featured-box-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom: 0;
  font-size: 30px;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  text-align: center;
}
.featured-box.style-2 p {
  margin-left: -50px;
}
.featured-box.style-3 {
  padding-left: 90px;
  padding-top: 0px;
}
.featured-box.style-3 .featured-box-icon {
  width: 70px;
  height: 70px;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.featured-box.style-4 {
  text-align: center;
}
.featured-box.style-4 .featured-box-icon {
  margin: 0 auto 24px;
  margin: 0 auto 1.5rem;
  width: 120px;
  height: 120px;
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.03);
}
.featured-box.style-4 .featured-box-icon i.fa,
.featured-box.style-4 .featured-box-icon i.fas,
.featured-box.style-4 .featured-box-icon i.far,
.featured-box.style-4 .featured-box-icon i.fal,
.featured-box.style-4 .featured-box-icon i.fab {
  font-size: 58px;
  font-size: 3.625rem;
  margin: 0 auto;
}
.featured-box.style-5 {
  text-align: center;
  background: #fff;
  border: 1px solid #f0f2f3;
  -webkit-box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.featured-box.style-5:hover {
  border: 1px solid #ebeded;
  -webkit-box-shadow: 0px 5px 1.5rem rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 1.5rem rgba(0, 0, 0, 0.15);
}
.featured-box.style-5 h3 {
  background: #f1f5f6;
  font-size: 16px;
  padding: 8px 0;
  margin-bottom: 0px;
}
.featured-box.style-5 .featured-box-icon {
  font-size: 50px;
  margin: 44px 0px;
}

/* Video Play button */
.btn-video-play {
  width: 74px;
  height: 74px;
  line-height: 74px;
  text-align: center;
  display: inline-block;
  font-size: 18px;
}

/* Testimonial */
.testimonial {
  background: #fff;
  border: 1px solid #f1f5f6;
  -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

/* Team */
.team {
  text-align: center;
  padding: 15px;
  background: #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.team:hover {
  -webkit-box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.15);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.team img {
  margin-bottom: 20px;
}
.team h3 {
  font-size: 18px;
}
.team p {
  margin-bottom: 0.5rem;
}

.portfolio {
  text-align: center;
}
.portfolio .portfolio-img {
  position: relative;
  overflow: hidden;
  display: -ms-flexbox;
  display: flex;
}
.portfolio .portfolio-overlay {
  text-align: center;
  display: -ms-flexbox !important;
  display: flex !important;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio:hover .portfolio-overlay {
  opacity: 1;
}
.portfolio .portfolio-overlay-details {
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
.portfolio .portfolio-img img {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.portfolio:hover .portfolio-img img {
  -webkit-transform: scale(1.04);
  transform: scale(1.04);
}
.portfolio .portfolio-details {
  padding: 1.5rem 0;
}

/* Accordion & Toggle */
.accordion .card {
  border: none;
  margin-bottom: 8px;
  margin-bottom: 0.5rem;
  background-color: transparent;
}
.accordion .card-header {
  padding: 0;
  border: none;
  background: none;
}
.accordion .card-header a {
  font-size: 16px;
  font-weight: normal;
  padding: 1rem 1.25rem 1rem 2.25rem;
  display: block;
  border-radius: 4px;
  position: relative;
}
.accordion:not(.accordion-alternate) .card-header a {
  background-color: #008046;
  color: #fff;
}
.accordion:not(.accordion-alternate) .card-header a.collapsed {
  background-color: #f1f2f4;
  color: #4c4d4d;
}
.accordion .card-header a:before {
  position: absolute;
  content: " ";
  left: 20px;
  top: calc(50% + 2px);
  width: 9px;
  height: 9px;
  border-color: #ccc;
  border-top: 2px solid;
  border-right: 2px solid;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.accordion .card-header a.collapsed:before {
  top: calc(50% - 2px);
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
}
.accordion .card-body {
  line-height: 26px;
  padding: 1rem 0 1rem 2.25rem;
}
.accordion.arrow-right .card-header a {
  padding-left: 1.25rem;
}
.accordion.arrow-right .card-header a:before {
  right: 15px;
  left: auto;
}
.accordion.accordion-alternate .card {
  margin: 0;
}
.accordion.accordion-alternate .card-header a {
  padding-left: 1.4rem;
  border-top: 1px solid #e4e9ec;
  border-radius: 0px;
}
.accordion.accordion-alternate .card:first-of-type .card-header a {
  border-top: 0px;
}
.accordion.accordion-alternate .card-header a:before {
  left: 6px;
}
.accordion.accordion-alternate .card-header a.collapsed {
  color: #4c4d4d;
}
.accordion.accordion-alternate .card-body {
  padding: 0rem 0 1rem 1.25rem;
}
.accordion.accordion-alternate.arrow-right .card-header a {
  padding-left: 0;
}
.accordion.accordion-alternate.arrow-right .card-header a:before {
  right: 0px;
  left: auto;
}
.accordion.toggle .card-header a:before {
  content: "-";
  border: none;
  font-size: 20px;
  height: auto;
  top: calc(50% + 2px);
  width: auto;
  -webkit-transform: translate(-50%, -50%) rotate(180deg);
  transform: translate(-50%, -50%) rotate(180deg);
}
.accordion.toggle .card-header a.collapsed:before {
  content: "+";
  top: calc(50% - 1px);
  -webkit-transform: translate(-50%, -50%) rotate(0deg);
  transform: translate(-50%, -50%) rotate(0deg);
}
.accordion.accordion-alternate.style-2 .card-header a {
  padding-left: 0px;
}
.accordion.accordion-alternate.style-2 .card-header a:before {
  right: 2px;
  left: auto;
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
  top: 50%;
}
.accordion.accordion-alternate.style-2 .card-header a.collapsed:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}
.accordion.accordion-alternate.style-2 .card-body {
  padding-left: 0px;
}
.accordion.accordion-alternate.popularRoutes .card-header .nav {
  margin-top: 3px;
}
.accordion.accordion-alternate.popularRoutes .card-header .nav a {
  font-size: 14px;
}
.accordion.accordion-alternate.popularRoutes .card-header a {
  padding: 0px 8px 0px 0px;
  border: none;
  font-size: inherit;
}
.accordion.accordion-alternate.popularRoutes .card-header a:before {
  content: none;
}
.accordion.accordion-alternate.popularRoutes .card-header h5 {
  cursor: pointer;
}
.accordion.accordion-alternate.popularRoutes .card-header h5:before {
  position: absolute;
  content: " ";
  right: 0px;
  top: 24px;
  width: 10px;
  height: 10px;
  opacity: 0.6;
  border-top: 2px solid;
  border-right: 2px solid;
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.accordion.accordion-alternate.popularRoutes .card-header h5.collapsed:before {
  top: 24px;
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
}
.accordion.accordion-alternate.popularRoutes .card-body {
  padding: 0;
}
.accordion.accordion-alternate.popularRoutes .card {
  border-bottom: 2px solid #e4e9ec;
  padding: 15px 0px;
}
.accordion.accordion-alternate.popularRoutes .routes-list {
  margin: 1rem 0px 0px 0px;
  padding: 0px;
  list-style: none;
}
.accordion.accordion-alternate.popularRoutes .routes-list a {
  color: inherit;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.accordion.accordion-alternate.popularRoutes .routes-list a:hover {
  color: #0071cc;
  text-decoration: underline;
}

/* Tabs */
.nav-tabs {
  border-bottom: 1px solid #d7dee3;
}
.nav-tabs .nav-item .nav-link {
  border: 0;
  background: transparent;
  font-size: 1rem;
  position: relative;
  border-radius: 0;
  padding: 1rem 20px;
  color: #7b8084;
  white-space: nowrap !important;
}
.nav-tabs .nav-item .nav-link.active {
  color: #0c2f55;
}
.nav-tabs .nav-item .nav-link.active:after {
  height: 3px;
  width: 100%;
  content: " ";
  background-color: #008046;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}
.nav-tabs.flex-column {
  border-right: 1px solid #d7dee3;
  border-bottom: 0px;
  padding: 1.5rem 0;
}
.nav-tabs.flex-column .nav-item .nav-link {
  border: 1px solid #d7dee3;
  border-right: 0px;
  background-color: #f6f7f8;
  font-size: 14px;
  padding: 0.75rem 1rem;
  color: #535b61;
}
.nav-tabs.flex-column .nav-item:first-of-type .nav-link {
  border-top-left-radius: 4px;
}
.nav-tabs.flex-column .nav-item:last-of-type .nav-link {
  border-bottom-left-radius: 4px;
}
.nav-tabs.flex-column .nav-item .nav-link.active {
  background-color: transparent;
  color: #008046;
}
.nav-tabs.flex-column .nav-item .nav-link.active:after {
  height: 100%;
  width: 2px;
  background: #fff;
  right: -1px;
  left: auto;
}
.nav-tabs.style-2 {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px 4px 0px 0px;
  border: 0px;
}
.nav-tabs.style-2 .nav-item {
  margin-bottom: 0px;
}
.nav-tabs.style-2 .nav-item .nav-link {
  color: #fff;
  font-size: 13px;
  padding: 0.7rem 1rem;
  text-transform: uppercase;
}
.nav-tabs.style-2 .nav-item .nav-link:hover {
  background: rgba(250, 250, 250, 0.2);
}
.nav-tabs.style-2 .nav-item .nav-link.active,
.nav-tabs.style-2 .nav-item .nav-link:hover.active {
  background: #008046;
}
.nav-tabs.style-2 .nav-item .nav-link.active:after {
  content: none;
}
.nav-tabs.style-3 {
  border: none;
  margin-bottom: 8px;
}
.nav-tabs.style-3.border-bottom {
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}
.nav-tabs.style-4.border-bottom {
  border-bottom: 1px solid rgba(250, 250, 250, 0.3) !important;
}
.nav-tabs.style-3 .nav-item .nav-link {
  color: #8298af;
  margin: 0 10px;
  padding: 0.6rem 0.9375rem;
  text-align: center;
  font-size: 13px;
  font-size: 0.8125rem;
}
.nav-tabs.style-3 .nav-item:first-child .nav-link {
  margin-left: 0px;
}
.nav-tabs.style-3 .nav-item .nav-link.active {
  color: #fff;
}
.nav-tabs.style-2 .nav-item .nav-link:hover.active {
  color: #fff;
}
.nav-tabs.style-3 .nav-item .nav-link:hover {
  color: #a6bcd3;
}
.nav-tabs.style-3 .nav-item .nav-link.active:after {
  height: 3px;
}
.nav-tabs.style-3 .nav-item .nav-link span {
  display: block;
  font-size: 30px;
  margin-bottom: 5px;
}
.nav-tabs.style-4 {
  border: none;
}
.nav-tabs.style-4 .nav-item {
  margin-right: 20px;
}
.nav-tabs.style-4 .nav-item .nav-link {
  color: #fff;
  opacity: 0.65;
  filter: alpha(opacity=65);
  text-align: center;
  font-size: 20px;
  padding-left: 0px;
  padding-right: 0px;
  padding-bottom: 0.7rem;
  font-weight: 500;
}
.nav-tabs.style-4 .nav-item .nav-link.active,
.nav-tabs.style-4 .nav-item .nav-link:hover.active {
  color: #fff;
  opacity: 1;
  filter: alpha(opacity=100);
}
.nav-tabs.style-4 .nav-item .nav-link.active::after {
  content: none;
}
.nav-tabs.style-5 {
  border-bottom: 0px !important;
}
.nav-tabs.style-5 .nav-item .nav-link {
  background: #f1f5f6;
  font-size: 18px;
  padding: 2rem 1rem;
  color: #8d999c;
}
.nav-tabs.style-5 .nav-item .nav-link.active {
  background: #fff;
  color: inherit;
  font-weight: 500;
}
.nav-tabs.style-5 .nav-item .nav-link.active:after {
  content: none;
}

.nav-tabs:not(.flex-column) {
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-x: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  -webkit-overflow-scrolling: touch;
}
.nav-tabs:not(.flex-column) .nav-item {
  margin-bottom: 0px;
}

@media (max-width: 575.98px) {
  .nav-tabs .nav-item .nav-link {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 10px;
    font-size: 0.875rem;
  }
}
@media (min-width: 992px) {
  .search-input-2 .form-control {
    border-radius: 0px;
  }
  .search-input-2 .custom-select:not(.custom-select-sm) {
    border-radius: 0px;
    height: calc(3.05rem);
  }
  .search-input-2 .btn {
    border-radius: 0px;
  }
  .search-input-2 .form-group:first-child .form-control,
  .search-input-2 .form-group:first-child .custom-select {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  .search-input-2 .form-group:last-child .btn {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .search-input-2 .form-control:focus,
  .search-input-2 .custom-select:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .search-input-2 .form-group .form-control,
  .search-input-2 .custom-select {
    border-left: none;
    border-top: none;
    border-bottom: none;
  }

  /* CSS hack for Chrome */
}
@media screen and (min-width: 992px) and (-webkit-min-device-pixel-ratio: 0) {
  .search-input-2 .custom-select:not(.custom-select-sm) {
    height: calc(3rem);
  }
  .search-input-2 .btn {
    line-height: inherit;
  }
}
@media (min-width: 992px) {
  /*  CSS hack for Firfox */
  @-moz-document url-prefix() {
    .search-input-2 .custom-select:not(.custom-select-sm) {
      height: calc(3.05rem);
    }
    .search-input-2 .btn {
      line-height: 1.4;
    }
  }
}
.search-input-line .form-control {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(250, 250, 250, 0.5);
  border-radius: 0px;
  padding-left: 0px !important;
  color: #ccc;
}
.search-input-line .form-control::-webkit-input-placeholder {
  color: #ccc;
}
.search-input-line .form-control:-moz-placeholder {
  /* FF 4-18 */
  color: #ccc;
}
.search-input-line .form-control::-moz-placeholder {
  /* FF 19+ */
  color: #ccc;
}
.search-input-line .form-control:-ms-input-placeholder,
.search-input-line .form-control::-ms-input-placeholder {
  /* IE 10+ */
  color: #ccc;
}
.search-input-line .custom-select {
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgba(250, 250, 250, 0.5);
  border-radius: 0px;
  padding-left: 0px;
  color: #ccc;
  background: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(250,250,250,0.6)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center;
  background-size: 13px 15px;
}
.search-input-line .form-control:focus,
.search-input-line .custom-select:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.search-input-line .form-control:not(output):-moz-ui-invalid:not(:focus),
.search-input-line
  .form-control:not(output):-moz-ui-invalid:-moz-focusring:not(:focus) {
  border-bottom: 2px solid #b00708;
  box-shadow: none;
  -webkit-box-shadow: none;
}
.search-input-line .icon-inside {
  color: #999;
}
.search-input-line select option {
  color: #333;
}
.search-input-line .travellers-dropdown input {
  color: #666;
}

/* Easy Responsive Tab Accordion */
.resp-htabs ul.resp-tabs-list {
  margin: 0px;
  padding: 0px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
  border-bottom: 1px solid #d7dee3;
}

.resp-tabs-list li {
  padding: 1rem 1.5rem !important;
  margin: 0;
  list-style: none;
  cursor: pointer;
  font-size: 16px;
}

h2.resp-accordion {
  cursor: pointer;
  padding: 5px;
  display: none;
}

.resp-tab-content {
  display: none;
  padding: 15px;
}

.resp-tab-active {
  margin-bottom: -1px !important;
  border-bottom: 3px solid #008046;
}

.resp-content-active,
.resp-accordion-active {
  display: block;
}

h2.resp-accordion {
  font-size: 16px;
  color: #777;
  border: 1px solid #e4e9ec;
  border-top: 0px solid #e4e9ec;
  margin: 0px;
  padding: 1rem 1.25rem;
}
h2.resp-tab-active {
  border-bottom: 0px solid #e4e9ec !important;
  margin-bottom: 0px !important;
  padding: 1rem 1.25rem !important;
}
h2.resp-tab-title:last-child {
  border-bottom: 12px solid #e4e9ec !important;
  background: blue;
}

/* Easy Responsive Vertical tabs */
.resp-vtabs ul.resp-tabs-list {
  margin: 0;
  padding: 0;
}
.resp-vtabs .resp-tabs-list li {
  display: block;
  padding: 15px 15px !important;
  margin: 0;
  cursor: pointer;
  font-size: 16px;
  color: #999;
  border: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.resp-vtabs .resp-tabs-list li:hover {
  color: #555;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.resp-vtabs .resp-tabs-list li span {
  font-size: 20px;
  text-align: center;
  width: 30px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}

h2.resp-accordion span {
  font-size: 20px;
  text-align: center;
  width: 30px;
  display: inline-block;
  float: left;
  margin-right: 15px;
}

.resp-vtabs .resp-tabs-container {
  padding: 0px;
}
.resp-vtabs .resp-tab-content {
  border: none;
}
.resp-vtabs li.resp-tab-active,
.resp-vtabs li.resp-tab-active:hover {
  color: #008046;
  -webkit-box-shadow: -5px 0px 24px -18px rgba(0, 0, 0, 0.4);
  box-shadow: -5px 0px 24px -18px rgba(0, 0, 0, 0.4);
  border-radius: 4px 0px 0px 4px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  margin-right: -1px !important;
  margin-bottom: 0px !important;
}

.resp-arrow {
  width: 0;
  height: 0;
  float: right;
  margin-top: 6px;
  border-color: #000;
  border-top: 1px solid;
  border-right: 1px solid;
  width: 9px;
  height: 9px;
  -webkit-transform: translate(-50%, -50%) rotate(135deg);
  transform: translate(-50%, -50%) rotate(135deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

h2.resp-tab-active {
  background: #f1f2f4 !important;
  color: #535b61;
}
h2.resp-tab-active i.resp-arrow {
  margin-top: 10px;
  transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

/* Accordion Styles */
.resp-easy-accordion h2.resp-accordion {
  display: block;
}
.resp-easy-accordion .resp-tab-content {
  border: 1px solid #e4e9ec;
}
.resp-easy-accordion .resp-tab-content:last-child {
  border-bottom: 1px solid #e4e9ec !important;
}

.resp-jfit {
  width: 100%;
  margin: 0px;
}

.resp-tab-content-active {
  display: block;
}

h2.resp-accordion:first-child {
  border-top: 1px solid #e4e9ec !important;
}

@media (max-width: 767.98px) {
  ul.resp-tabs-list {
    display: none !important;
  }

  h2.resp-accordion {
    display: block;
  }

  .resp-vtabs .resp-tab-content,
  .resp-htabs .resp-tab-content {
    border: 1px solid #e4e9ec;
  }

  .resp-vtabs .resp-tabs-container {
    border: none;
    float: none;
    width: 100%;
    min-height: initial;
    clear: none;
  }

  .resp-accordion-closed {
    display: none !important;
  }

  .resp-vtabs .resp-tab-content:last-child {
    border-bottom: 1px solid #e4e9ec !important;
  }
}
/* Custom Background */
.hero-wrap {
  position: relative;
}
.hero-wrap .hero-mask,
.hero-wrap .hero-bg,
.hero-wrap .hero-bg-slideshow {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.hero-wrap .hero-mask {
  z-index: 1;
}
.hero-wrap .hero-content {
  position: relative;
  z-index: 2;
}
.hero-wrap .hero-bg-slideshow {
  z-index: 0;
}
.hero-wrap .hero-bg {
  z-index: 0;
  background-attachment: scroll;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  transition: background-image 300ms ease-in 200ms;
}
.hero-wrap .hero-bg.hero-bg-scroll {
  background-attachment: scroll;
}
.hero-wrap .hero-bg-slideshow .hero-bg {
  background-attachment: inherit;
}
.hero-wrap .hero-bg-slideshow.owl-carousel .owl-stage-outer,
.hero-wrap .hero-bg-slideshow.owl-carousel .owl-stage,
.hero-wrap .hero-bg-slideshow.owl-carousel .owl-item {
  height: 100%;
}

/* Owl Carousel */
.owl-theme.single-slideshow .owl-dots {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.owl-theme.single-slideshow .owl-dots .owl-dot.active span,
.owl-theme.single-slideshow .owl-dots .owl-dot:hover span {
  background: rgba(250, 250, 250, 0.5);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}
.owl-theme.single-slideshow .owl-dots .owl-dot span {
  border-color: rgba(250, 250, 250, 0.5);
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
}

.owl-carousel.single-slideshow .owl-nav button.owl-prev,
.owl-carousel.single-slideshow .owl-nav button.owl-next {
  font-size: 17px;
  width: 45px;
  height: 45px;
  top: calc(50% - 22px);
}
.owl-carousel.single-slideshow .owl-nav button.owl-prev {
  left: 10px;
}
.owl-carousel.single-slideshow .owl-nav button.owl-next {
  right: 10px;
}
.owl-carousel.single-slideshow .owl-item img {
  border-radius: 0.25rem;
}
.owl-carousel.single-slideshow .owl-stage {
  padding: 0;
}
.owl-carousel .hero-wrap .hero-bg {
  background-attachment: inherit;
}

/* Brands Grid */
.brands-grid {
  overflow: hidden;
}
.brands-grid > .row > div {
  padding-top: 20px;
  padding-bottom: 20px;
}
.brands-grid.separator-border > .row > div:after,
.brands-grid.separator-border > .row > div:before {
  content: "";
  position: absolute;
}
.brands-grid.separator-border > .row > div:after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px dotted #e0dede;
}
.brands-grid.separator-border > .row > div:before {
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px dotted #e0dede;
}
.brands-grid > .row > div a {
  opacity: 0.7;
  color: #444;
}
.brands-grid > .row > div a:hover {
  opacity: 1;
  color: #008046;
}

/* Banner */
.banner .item {
  position: relative;
}
.banner .item img {
  vertical-align: middle;
}
.banner .caption {
  position: absolute;
  z-index: 2;
  bottom: 0;
  width: 100%;
  padding: 15px 20px;
}
.banner .caption h2,
.banner .caption h3 {
  font-size: 19px;
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.banner .caption p {
  color: rgba(250, 250, 250, 0.8);
  margin-bottom: 0px;
}
.banner .rounded .banner-mask,
.banner .rounded img {
  border-radius: 0.25rem;
}
.banner .banner-mask {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  backface-visibility: hidden;
  background: -webkit-linear-gradient(
    top,
    rgba(0, 0, 0, 0.01),
    rgba(0, 0, 0, 0.6)
  );
  background: -moz-linear-gradient(
    top,
    rgba(0, 0, 0, 0.01),
    rgba(0, 0, 0, 0.6)
  );
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.01), black);
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.01), black);
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.01), black);
  opacity: 0.7;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.banner .item:hover .banner-mask {
  opacity: 0.95;
  filter: alpha(opacity=95);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.banner.style-2 .caption {
  background: rgba(0, 0, 0, 0.5);
}
.banner.style-2 .banner-mask {
  opacity: 0;
}
.banner.style-2 .item:hover .banner-mask {
  opacity: 0.4;
}
.banner.style-2 .item:hover .caption h2 {
  margin-left: 8px;
}

/* Simple ul */
.simple-ul > li {
  position: relative;
  list-style-type: none;
  line-height: 24px;
}
.simple-ul > li:after {
  content: " ";
  position: absolute;
  top: 12px;
  left: -15px;
  border-color: #000;
  border-top: 1px solid;
  border-right: 1px solid;
  width: 6px;
  height: 6px;
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

/* Steps Progress bar */
.widget-steps > .step {
  padding: 0;
  position: relative;
}
.widget-steps > .step .step-name {
  font-size: 16px;
  margin-bottom: 5px;
  text-align: center;
}
.widget-steps > .step > .step-dot {
  position: absolute;
  width: 30px;
  height: 30px;
  display: block;
  background: #fff;
  border: 1px solid #008046;
  top: 45px;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
  border-radius: 50%;
}
.widget-steps > .step > .step-dot:after {
  width: 10px;
  height: 10px;
  border-radius: 50px;
  position: absolute;
  top: 9px;
  left: 9px;
}
.widget-steps > .step.complete > .step-dot {
  background: #008046;
}
.widget-steps > .step.complete > .step-dot:after {
  content: "\f00c";
  font-weight: 900;
  color: #fff;
  font-family: "Font Awesome 5 Free";
  top: 3px;
  left: 7px;
}
.widget-steps > .step.active > .step-dot:after {
  background: #008046;
  content: "";
}
.widget-steps > .step > .progress {
  position: relative;
  background: #bbb;
  border-radius: 0px;
  height: 1px;
  box-shadow: none;
  margin: 22px 0;
}
.widget-steps > .step > .progress > .progress-bar {
  width: 0px;
  box-shadow: none;
  background: #008046;
}
.widget-steps > .step.complete > .progress > .progress-bar {
  width: 100%;
}
.widget-steps > .step.active > .progress > .progress-bar {
  width: 50%;
}
.widget-steps > .step:first-child.active > .progress > .progress-bar {
  width: 0%;
}
.widget-steps > .step:last-child.active > .progress > .progress-bar {
  width: 100%;
}
.widget-steps > .step.disabled > .step-dot {
  border-color: #bbb;
}
.widget-steps > .step:first-child > .progress {
  left: 50%;
  width: 50%;
}
.widget-steps > .step:last-child > .progress {
  width: 50%;
}
.widget-steps > .step.disabled a.step-dot {
  pointer-events: none;
}

@media (max-width: 575.98px) {
  .widget-steps > .step .step-name {
    font-size: 14px;
  }
}
/* Demo banner */
.demos-banner {
  background-repeat: repeat;
  background-size: cover;
  height: 880px;
  background-position: 0px 0px;
  animation: move 30s linear infinite;
  -moz-animation: move 30s linear infinite;
  -webkit-animation: move 30s linear infinite;
  -ms-animation: move 30s linear infinite;
  -o-animation: move 30s linear infinite;
}

@keyframes move {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -2324px 0px;
  }
}
@-moz-keyframes move {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -2324px 0px;
  }
}
@-webkit-keyframes move {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -2324px 0px;
  }
}
@-ms-keyframes move {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -2324px 0px;
  }
}
@-o-keyframes move {
  0% {
    background-position: 0px 0px;
  }
  100% {
    background-position: -2324px 0px;
  }
}
/* =================================== */
/*  Footer
/* =================================== */
#footer {
  background: #000000;
  color: #fff !important;
  padding: 66px 0px;
  padding: 4.125rem 0;
  border-top: 1px solid #ecf3f5;
  padding-bottom: 10px;
}
#footer .nav .nav-item {
  display: inline-block;
  line-height: 12px;
  margin: 0;
  padding: 0 10px;
}
#footer .nav .nav-item:first-child {
  padding-left: 0px;
}
#footer .nav .nav-item:last-child {
  padding-right: 0px;
}
#footer .nav .nav-item .nav-link {
  padding: 0;
  margin: 0.5rem 0px;
  color: #252b33;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#footer .nav .nav-item .nav-link:focus {
  color: #008046;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#footer .nav .nav-link:hover {
  color: #008046;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#footer .footer-copyright {
  text-align-last: center;
  padding: 0px 0px;
  color: #67727c;
}
#footer .footer-copyright .nav .nav-item .nav-link {
  color: #67727c;
}
#footer .footer-copyright .nav .nav-link:hover {
  color: #008046;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#footer .nav.flex-column .nav-item {
  padding: 0px;
}
#footer .nav.flex-column .nav-item .nav-link {
  margin: 0.7rem 0px;
}
#footer.footer-text-light {
  color: rgba(250, 250, 250, 0.8);
}
#footer.footer-text-light .nav .nav-item .nav-link {
  color: rgba(250, 250, 250, 0.8);
}
#footer.footer-text-light .nav .nav-item .nav-link:hover {
  color: #fafafa;
}
#footer.footer-text-light .footer-copyright {
  border-color: rgba(250, 250, 250, 0.15);
  color: rgba(250, 250, 250, 0.5);
}
#footer.footer-text-light:not(.bg-primary)
  .social-icons-light.social-icons
  li
  a {
  color: rgba(250, 250, 250, 0.8);
}
#footer.footer-text-light:not(.bg-primary)
  .social-icons-light.social-icons
  li
  a:hover {
  color: #fafafa;
}
#footer.footer-text-light.bg-primary {
  color: #fff;
}
#footer.footer-text-light.bg-primary .nav .nav-item .nav-link {
  color: #fff;
}
#footer.footer-text-light.bg-primary .nav .nav-item .nav-link:hover {
  color: rgba(250, 250, 250, 0.7);
}
#footer.footer-text-light.bg-primary .footer-copyright {
  border-color: rgba(250, 250, 250, 0.15);
  color: rgba(250, 250, 250, 0.9);
}
#footer.footer-text-light.bg-primary :not(.social-icons) a {
  color: #fff;
}
#footer.footer-text-light.bg-primary :not(.social-icons) a:hover {
  color: rgba(250, 250, 250, 0.7);
}

/* Newsleter */
.newsletter .form-control {
  height: 38px !important;
  font-size: 14px;
}
.newsletter .btn {
  height: 38px;
  padding-top: 0;
  padding-bottom: 0px;
  font-size: 14px;
}

/* Social Icons */
.social-icons {
  margin: 0;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  list-style: none;
}
.social-icons li {
  margin: 0px 6px 4px;
  padding: 0;
  overflow: visible;
}
.social-icons li:last-child {
  margin-right: 0px;
}
.social-icons li a {
  display: block;
  height: 26px;
  line-height: 26px;
  width: 26px;
  font-size: 18px;
  text-align: center;
  color: #4d555a;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.social-icons li i {
  line-height: inherit;
}
.social-icons.social-icons-sm li {
  margin: 0px 4px 4px;
}
.social-icons.social-icons-sm li a {
  font-size: 14px;
}
.social-icons.social-icons-lg li a {
  width: 34px;
  height: 34px;
  line-height: 34px;
  font-size: 22px;
}
.social-icons.social-icons-light li a {
  color: #eee;
}
.social-icons.social-icons-muted li a {
  color: #aab1b8;
}
.social-icons li:hover a {
  color: #999;
}
.social-icons li:hover.social-icons-twitter a,
.social-icons.social-icons-colored li.social-icons-twitter a {
  color: #00acee;
}
.social-icons li:hover.social-icons-facebook a,
.social-icons.social-icons-colored li.social-icons-facebook a {
  color: #3b5998;
}
.social-icons li:hover.social-icons-linkedin a,
.social-icons.social-icons-colored li.social-icons-linkedin a {
  color: #0e76a8;
}
.social-icons li:hover.social-icons-rss a,
.social-icons.social-icons-colored li.social-icons-rss a {
  color: #ee802f;
}
.social-icons li:hover.social-icons-google a,
.social-icons.social-icons-colored li.social-icons-google a {
  color: #dd4b39;
}
.social-icons li:hover.social-icons-pinterest a,
.social-icons.social-icons-colored li.social-icons-pinterest a {
  color: #cc2127;
}
.social-icons li:hover.social-icons-youtube a,
.social-icons.social-icons-colored li.social-icons-youtube a {
  color: #c4302b;
}
.social-icons li:hover.social-icons-instagram a,
.social-icons.social-icons-colored li.social-icons-instagram a {
  color: #3f729b;
}
.social-icons li:hover.social-icons-skype a,
.social-icons.social-icons-colored li.social-icons-skype a {
  color: #00aff0;
}
.social-icons li:hover.social-icons-email a,
.social-icons.social-icons-colored li.social-icons-email a {
  color: #6567a5;
}
.social-icons li:hover.social-icons-vk a,
.social-icons.social-icons-colored li.social-icons-vk a {
  color: #2b587a;
}
.social-icons li:hover.social-icons-xing a,
.social-icons.social-icons-colored li.social-icons-xing a {
  color: #126567;
}
.social-icons li:hover.social-icons-tumblr a,
.social-icons.social-icons-colored li.social-icons-tumblr a {
  color: #34526f;
}
.social-icons li:hover.social-icons-reddit a,
.social-icons.social-icons-colored li.social-icons-reddit a {
  color: #c6c6c6;
}
.social-icons li:hover.social-icons-delicious a,
.social-icons.social-icons-colored li.social-icons-delicious a {
  color: #205cc0;
}
.social-icons li:hover.social-icons-stumbleupon a,
.social-icons.social-icons-colored li.social-icons-stumbleupon a {
  color: #f74425;
}
.social-icons li:hover.social-icons-digg a,
.social-icons.social-icons-colored li.social-icons-digg a {
  color: #191919;
}
.social-icons li:hover.social-icons-blogger a,
.social-icons.social-icons-colored li.social-icons-blogger a {
  color: #fc4f08;
}
.social-icons li:hover.social-icons-flickr a,
.social-icons.social-icons-colored li.social-icons-flickr a {
  color: #ff0084;
}
.social-icons li:hover.social-icons-vimeo a,
.social-icons.social-icons-colored li.social-icons-vimeo a {
  color: #86c9ef;
}
.social-icons li:hover.social-icons-yahoo a,
.social-icons.social-icons-colored li.social-icons-yahoo a {
  color: #720e9e;
}
.social-icons li:hover.social-icons-googleplay a,
.social-icons.social-icons-colored li.social-icons-googleplay a {
  color: #dd4b39;
}
.social-icons li:hover.social-icons-apple a,
.social-icons.social-icons-colored li.social-icons-apple a {
  color: #000;
}
.social-icons.social-icons-colored li:hover a {
  color: #999;
}

/* Back to Top */
#back-to-top {
  display: none;
  position: fixed;
  z-index: 1030;
  bottom: 8px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  text-align: center;
  color: #fff;
  font-size: 16px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 0.25rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}
#back-to-top:hover {
  background-color: #008046;
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.25);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 575.98px) {
  #back-to-top {
    z-index: 1029;
  }
}
/* =================================== */
/*  Extras
/* =================================== */
/* Bootstrap Specific */
.form-control,
.custom-select {
  /* border-color: #dae1e3; */
  font-size: 16px;
  color: #656565;
}

.form-control:not(.form-control-sm) {
  padding: 0.81rem 0.96rem;
  height: inherit;
}

.form-control-sm {
  font-size: 14px;
}

.icon-inside {
  position: absolute;
  right: 15px;
  top: calc(50% - 11px);
  pointer-events: none;
  font-size: 18px;
  font-size: 1.125rem;
  color: #c4c3c3;
  z-index: 3;
}

.form-control-sm + .icon-inside {
  font-size: 0.875rem !important;
  font-size: 14px;
  top: calc(50% - 13px);
}

select.form-control:not([size]):not([multiple]):not(.form-control-sm) {
  height: auto;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.custom-select:not(.custom-select-sm) {
  height: calc(3.05rem + 3px);
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

.col-form-label-sm {
  font-size: 13px;
}

.custom-select-sm {
  padding-left: 5px !important;
  font-size: 14px;
}

.custom-select:not(.custom-select-sm).border-0 {
  height: 3rem;
}

.form-control:focus,
.custom-select:focus {
  -webkit-box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
  box-shadow: 0 0 5px rgba(128, 189, 255, 0.5);
  /* border-top: none;
  border-left: none;
  border-right: none;
  box-shadow: none !important; */
}

.form-control:focus[readonly] {
  box-shadow: none;
}

.input-group-text {
  border-color: #dae1e3;
  background-color: #f1f5f6;
  color: #656565;
}

.form-control::-webkit-input-placeholder {
  color: #707070;
  font-size: 19px;
  padding-left: 5px;
}
.form-control:-moz-placeholder {
  /* FF 4-18 */
  color: #707070;
  font-size: 19px;
  padding-left: 5px;
}
.form-control::-moz-placeholder {
  /* FF 19+ */
  color: #707070;
  font-size: 19px;
  padding-left: 5px;
}
.form-control:-ms-input-placeholder,
.form-control::-ms-input-placeholder {
  /* IE 10+ */
  color: #707070;
  font-size: 19px;
  padding-left: 5px;
}

.btn {
  padding: 0.8rem 2rem;
  font-weight: 500;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.btn-sm {
  padding: 0.5rem 1rem;
}

.btn:not(.btn-link):not(.cancelBtn):not(.applyBtn) {
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
}
.btn:not(.btn-link):not(.cancelBtn):not(.applyBtn):hover {
  -webkit-box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.3);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.input-group-append .btn,
.input-group-prepend .btn {
  -webkit-box-shadow: none;
  box-shadow: none;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.input-group-append .btn:hover,
.input-group-prepend .btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media (max-width: 575.98px) {
  .btn:not(.btn-sm) {
    padding: 0.81rem 1rem;
  }

  .input-group > .input-group-append > .btn,
  .input-group > .input-group-prepend > .btn {
    padding: 0 0.75rem;
  }
}
.bg-primary,
.badge-primary {
  background-color: #008046 !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.text-primary,
.btn-light,
.btn-outline-light:hover,
.btn-link {
  color: #008046 !important;
}

a.text-primary:focus,
a.text-primary:hover,
.btn-link:hover {
  color: #f91351 !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-muted {
  color: #8e9a9d !important;
}

.text-light {
  color: #dee3e4 !important;
}

.text-body {
  color: #4c4d4d !important;
}

a.bg-primary:focus,
a.bg-primary:hover,
button.bg-primary:focus,
button.bg-primary:hover {
  background-color: #f91351 !important;
}

.border-primary {
  border-color: #008046 !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.btn-primary {
  background-color: #008046 !important;
  border-color: #008046 !important;
}
.btn-primary:hover {
  background-color: #5cbf6a !important;
  border-color: #5cbf6a !important;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: #5cbf6a !important;
  border-color: #5cbf6a !important;
}
.btn-primary:not(:disabled):not(.disabled).active:hover,
.btn-primary:not(:disabled):not(.disabled):active:hover {
  background-color: #008046 !important;
  border-color: #008046 !important;
}

.btn-primary.focus,
.btn-primary:focus {
  background-color: #5cbf6a !important;
  border-color: #5cbf6a !important;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-outline-primary,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #008046;
  border-color: #008046;
}
.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active:hover,
.btn-outline-primary:not(:disabled):not(.disabled):active:hover {
  background-color: #008046;
  border-color: #008046;
  color: #fff;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #fff;
}

.progress-bar,
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link,
.dropdown-item.active,
.dropdown-item:active {
  background-color: #008046;
}

.page-item.active .page-link,
.custom-radio .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label::before,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before,
.custom-control-input:checked ~ .custom-control-label:before {
  background-color: #03eba3;
  border-color: #ffffff;
}

.list-group-item.active {
  background-color: #008046;
  border-color: #008046;
}

.page-link {
  color: #008046;
}
.page-link:hover {
  color: #f91351;
}

/* Pagination */
.page-link {
  border: none;
  border-radius: 0.25rem;
  margin: 0 0.22rem;
  font-size: 16px;
  font-size: 1rem;
}
.page-link:hover {
  background-color: #e9eff0;
}

/* Vertical Multilple input group */
.vertical-input-group .input-group:first-child {
  padding-bottom: 0;
}
.vertical-input-group .input-group:first-child * {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.vertical-input-group .input-group:last-child {
  padding-top: 0;
}
.vertical-input-group .input-group:last-child * {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.vertical-input-group .input-group:not(:last-child):not(:first-child) {
  padding-top: 0;
  padding-bottom: 0;
}
.vertical-input-group .input-group:not(:last-child):not(:first-child) * {
  border-radius: 0;
}
.vertical-input-group .input-group:not(:first-child) * {
  border-top: 0;
}

/* Slider Range (jQuery UI) */
.ui-slider-horizontal {
  height: 0.2em;
  margin-left: 11px;
  margin-right: 11px;
}
.ui-slider-horizontal .ui-slider-handle {
  top: -0.7em;
  margin-left: -0.7em;
  border-radius: 100%;
  background: #fff;
  width: 1.5em;
  height: 1.5em;
}

.ui-slider.ui-widget.ui-widget-content {
  border: none;
  background: #eee;
  margin-bottom: 15px;
}
.ui-slider .ui-widget-header {
  background: #008046;
}

.ui-menu.ui-widget.ui-widget-content {
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.176);
  border: none;
  overflow: hidden;
  overflow-y: auto;
  max-height: 50vh;
  border-radius: 4px;
}
.ui-menu .ui-menu-item-wrapper {
  padding: 6px 0.75rem 6px 0.9rem;
}
.ui-menu.ui-widget-content .ui-state-active {
  background: #008046;
  border-color: #008046;
}
.ui-menu .ui-menu-divider {
  display: none;
}

/* Bootstrap-select */
.bootstrap-select.form-control {
  padding: 0;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder {
  color: #4c4d4d;
  font-weight: normal;
}
.bootstrap-select > .dropdown-toggle.bs-placeholder:hover,
.bootstrap-select > .dropdown-toggle.bs-placeholder:focus,
.bootstrap-select > .dropdown-toggle.bs-placeholder:active {
  color: #4c4d4d;
  font-weight: normal;
}
.bootstrap-select .dropdown-item:focus,
.bootstrap-select .dropdown-item:hover:not(.dropdown-item.active) {
  background-color: #f5f5f5;
}
.bootstrap-select .dropdown-menu li.active small {
  color: rgba(255, 255, 255, 0.7) !important;
}
.bootstrap-select > .dropdown-toggle {
  padding: 0.7rem 1.5rem 0.7rem 0.75rem !important;
  box-shadow: none !important;
  font-weight: normal;
}
.bootstrap-select > .dropdown-toggle.custom-select:after {
  border: none;
}
.bootstrap-select .dropdown-toggle.custom-select .filter-option {
  height: auto;
}
.bootstrap-select .dropdown-toggle .filter-option-inner-inner {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.bootstrap-select .dropdown-menu li a {
  -ms-flex-align: center !important;
  align-items: center !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.bootstrap-select.fit-width .dropdown-toggle .filter-option {
  width: auto;
}
.bootstrap-select > select.mobile-device:focus + .dropdown-toggle,
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.currency-flag {
  min-width: 24px;
}

.currency-flag-sm {
  min-width: 16px;
}

.currency-flag-lg {
  min-width: 36px;
}

/* styles-switcher */
#styles-switcher {
  background: #fff;
  width: 202px;
  position: fixed;
  top: 35%;
  z-index: 99;
  padding: 20px;
  left: -202px;
}
#styles-switcher ul {
  padding: 0;
}
#styles-switcher ul li {
  list-style-type: none;
  width: 25px;
  height: 25px;
  margin: 4px 2px;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
#styles-switcher ul li.blue {
  background: #007bff;
}
#styles-switcher ul li.brown {
  background: #795548;
}
#styles-switcher ul li.purple {
  background: #6f42c1;
}
#styles-switcher ul li.indigo {
  background: #6610f2;
}
#styles-switcher ul li.red {
  background: #dc3545;
}
#styles-switcher ul li.orange {
  background: #fd7e14;
}
#styles-switcher ul li.yellow {
  background: #ffc107;
}
#styles-switcher ul li.pink {
  background: #e83e8c;
}
#styles-switcher ul li.teal {
  background: #20c997;
}
#styles-switcher ul li.cyan {
  background: #17a2b8;
}
#styles-switcher ul li.active {
  transform: scale(0.7);
  cursor: default;
}
#styles-switcher .switcher-toggle {
  position: absolute;
  background: #333;
  color: #fff;
  font-size: 1.25rem;
  border-radius: 0px 4px 4px 0;
  right: -40px;
  top: 0;
  width: 40px;
  height: 40px;
  padding: 0;
}
#styles-switcher #reset-color {
  background: #008046;
}

/* .bgLogin {
  background-image: url("/src/assets/images/bg/image-3.jpg");
}

.bgImg1 {
  background-image: url("/src/assets/images/bg/image-1.jpg");
}

.bgImg2 {
  background-image: url("/src/assets/images/bg/image-2.jpg");
} */

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.1) !important;
}

.backBtn {
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  background-color: #008046;
  padding: 5px;
  font-weight: 500;
  border-radius: 0.25rem;
}

.backBtn:hover {
  color: #008046;
  background-color: #fff;
  border: 1px solid #008046;
}

.summaryAvater {
  border-radius: 0.23rem;
  width: 60px;
  height: 60px;
  border: 1px solid #ccc;
}

.read {
  color: gray !important;
}

.slideStyle {
  padding: 0 23%;
}
@media (max-width: 575.98px) {
  .slideStyle {
    padding: 0;
  }
}
.promoSlideImg {
  padding: 0 20%;
}
@media (max-width: 575.98px) {
  .promoSlideImg {
    padding: 0;
  }
}
.ModalStyle {
  padding-top: 10%;
}
@media (max-width: 575.98px) {
  .ModalStyle {
    padding-top: 0;
  }
}
.mapWrapper {
  width: 300px;
  height: 300px;
}
.mapContainer {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  height: 48%;
  width: 64%;
  margin-top: 25%;
  margin-left: 18%;
}

#geocoder-container > div {
  min-width: 50%;
  margin-left: 25%;
}

/* Scrollbar */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0.25rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b2b2b2;
  border-radius: 0.25rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: gray;
}

.topicsQ {
  font-weight: 600 !important;
  color: #008046 !important;
  padding: 12px 0;
}
.viewOffline {
  width: 330px;
  padding: 20px 20px 20px 190px;
  cursor: pointer;
}
@media (max-width: 575.98px) {
  .viewOffline {
    width: 235px;
    padding: 20px 20px 20px 120px;
  }
}

.map-container {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.sidebarStyle {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
}

.InputStyle {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #707070;
  border-radius: 0;
  background-color: transparent !important;
}

.FormGroupStyleCounter {
  width: 77%;
}
@media (max-width: 768px) {
  .FormGroupStyleCounter {
    width: 52%;
    padding-left: 11%;
  }
}

.FormGroupStyle {
  width: 77%;
  padding-left: 11%;
}
@media (max-width: 768px) {
  .FormGroupStyle {
    width: 83%;
    padding-left: 1%;
  }
}
@media (max-width: 575.98px) {
  .colOnMob {
    padding: 10% 0 0 7% !important;
  }
}

.loginPage {
  padding-top: 200px;
}
@media (max-width: 575.98px) {
  .loginPage {
    padding-top: 0;
  }
}
.gradientBalance {
  background-image: linear-gradient(45deg, #d45f93, #984caa, #243680);
  color: #ffff;
  height: 280px;
}
.vertical-ul {
  list-style-type: none;
  margin: 0;
  border-radius: 50px;
  box-shadow: 0px 0px 6px #00000029;
  padding: 13px;
  background-color: #fff;
  position: absolute;
  height: 97%;
}
@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .vertical-ul {
    padding: 13px 3px;
  }
}
.vertical-li {
  padding-bottom: 35px;
  height: 90px;
}
.vertical-li a {
  display: block;
  color: #fff;
  text-align: center;
  padding-top: 23%;
  text-decoration: none;
}

.vertical-li a.active {
  border-radius: 50px;
  width: 60px;
  height: 60px;
  background-color: #008046;
  color: #fff !important;
}
@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .vertical-li a.active {
    width: 45px;
    height: 45px;
  }
}
.vertical-li a:hover:not(.active) {
  border-radius: 50px;
  width: 60px;
  height: 60px;
  background-color: #008046;
  color: #fff !important;
}
@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .vertical-li a:hover:not(.active) {
    width: 45px;
    height: 45px;
  }
}

.NextStore {
  border-radius: 50px;
  border: 1px solid rgb(252, 40, 97);
  color: rgb(252, 40, 97);
  width: 70%;
  background-color: transparent;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
}
.NextStore:hover {
  color: white;
  background-color: #008046;
}

.AddStore {
  border-radius: 50px;
  border: 1px solid rgb(252, 40, 97);
  color: rgb(252, 40, 97);
  background-color: transparent;
  font-weight: 500;
  font-size: 14px;
  padding: 10px 20px;
}
.AddStore:hover {
  color: white;
  background-color: #008046;
}

.divICON {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  width: 54px;
  height: 54px;
  text-align: center;
}

.divQR {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  width: 200px;
  height: 200px;
  text-align: center;
  background-color: #f0f6fa;
  padding-top: 10px;
  margin-bottom: 10px;
}

.divQR2 {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 8px;
  width: 250px;
  /* height: 250px; */
  text-align: center;
  background-color: #f0f6fa;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .divQR2 {
    width: 200px;
    /* height: 200px; */
  }
}

.bgImg {
  background-image: url("../images/hbg.png");
}
.bgImg2 {
  background-image: url("../images/bg2.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 120%;
  height: 101%;
}

.devBg {
  background-image: url("../images/devBg.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.MerchantInput {
  border-radius: 16px;
  background-color: transparent !important;
}

.clientBg {
  background-image: url("../images/clientBg.svg");
}
.merchntText {
  width: 195px;
  font-weight: 700;
  font-size: 16px;
  color: #2d335b;
  padding-top: 10px;
  line-height: 1.3 !important;
}
.passHeader {
  font-size: 31px;
  font-weight: 500;
  color: #2b335e;
  padding-bottom: 10px;
  padding-top: 50px;
}
@media (max-width: 575.98px) {
  .WebView {
    display: none !important;
  }
}

.MobileView {
  display: none !important;
}
@media (max-width: 575.98px) {
  .MobileView {
    display: block !important;
  }
}

#events {
  background-color: rgba(191, 191, 191, 0.15);
  padding: 20px;
  margin-top: 20px;
}

#events > div {
  padding-bottom: 5px;
}

#events > div:after {
  content: "";
  display: table;
  clear: both;
}

#events #clear {
  float: right;
}

#events .caption {
  float: left;
  font-weight: bold;
  font-size: 115%;
  line-height: 115%;
  padding-top: 7px;
}

#events ul {
  list-style: none;
  max-height: 100px;
  overflow: auto;
  margin: 0;
}

#events ul li {
  padding: 7px 0;
  border-bottom: 1px solid #dddddd;
}

#events ul li:last-child {
  border-bottom: none;
}
#footer2 {
  border-top: 1px solid #ecf3f5;
}
#footer2 .footer-copyright2 {
  padding-top: 15px;
  padding-bottom: 15px;
  color: #595c80;
}

.field-icon {
  cursor: pointer;
  float: right;
  margin-left: -25px;
  margin-top: -33px;
  position: relative;
  z-index: 2;
  padding-right: 8%;
  color: #707070;
}

.field-icons {
  float: left;
  margin-top: -33px;
  position: relative;
  z-index: 2;
  color: #707070;
}
.back-Btn {
  height: 50px;
  width: 50px;
  background-color: white;
  border-radius: 50px;
  box-shadow: 0px 0px 6px #00000029;
  text-align: center;
  padding-top: 15px;
  padding-right: 4px;
}
.statusDiv {
  border-radius: 50px;
  border: 1px solid;
  padding: 5px 10px;
  line-height: 2.1;
  white-space: nowrap;
}

/* 
  You want a simple and fancy tooltip?
  Just copy all [data-tooltip] blocks:
*/
[data-tooltip] {
  position: relative;
  z-index: 10;
}

/* Positioning and visibility settings of the tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  /* left: 50%;  */
  right: 110%;
  bottom: calc(100% + 5px); /* 5px is the size of the arrow */
  pointer-events: none;
  transition: 0.2s;
  will-change: transform;
}

/* The actual tooltip with a dynamic width */
[data-tooltip]:before {
  content: attr(data-tooltip);
  padding: 10px 18px;
  min-width: 50px;
  max-width: 300px;
  width: max-content;
  width: -moz-max-content;
  border-radius: 6px;
  font-size: 14px;
  background-color: #008046;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
  color: #fff;
  text-align: center;
  white-space: pre-wrap;
  transform: translate(-50%, -5px) scale(0.5);
}

/* Tooltip arrow */
[data-tooltip]:after {
  content: "";
  border-style: solid;
  border-width: 5px 5px 0px 5px; /* CSS triangle */
  border-color: #008046;
  transition-duration: 0s; /* If the mouse leaves the element, 
                              the transition effects for the 
                              tooltip arrow are "turned off" */
  transform-origin: top; /* Orientation setting for the
                              slide-down effect */
  transform: translateX(-50%) scaleY(0);
}

/* Tooltip becomes visible at hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1;
}
/* Scales from 0.5 to 1 -> grow effect */
[data-tooltip]:hover:before {
  transition-delay: 0.3s;
  transform: translate(-50%, -5px) scale(1);
}
/* 
  Arrow slide down effect only on mouseenter (NOT on mouseleave)
*/
[data-tooltip]:hover:after {
  transition-delay: 0.5s; /* Starting after the grow effect */
  transition-duration: 0.2s;
  transform: translateX(-50%) scaleY(1);
}
/*
  That's it.
*/

/* Tooltip */

/* RIGHT */
[data-tooltip-location="right"]:before,
[data-tooltip-location="right"]:after {
  left: calc(100% + 5px);
  /* left: auto !important; */
  bottom: 50%;
}

[data-tooltip-location="right"]:before {
  transform: translate(5px, 50%) scale(0.5);
}
[data-tooltip-location="right"]:hover:before {
  transform: translate(5px, 50%) scale(1);
}

[data-tooltip-location="right"]:after {
  border-width: 5px 5px 5px 0px;
  border-color: transparent #008046;
  transform-origin: right;
  transform: translateY(50%) scaleX(0);
}
[data-tooltip-location="right"]:hover:after {
  transform: translateY(50%) scaleX(1);
}

#partitioned {
  letter-spacing: 63px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 69px 1px;
  background-repeat: repeat-x;
  background-position-x: 48px;
  width: 460px;
}
@media (max-width: 768px) {
  #partitioned {
    background-size: 47px 1px;
    letter-spacing: 42px;
    padding-left: 26px !important;
  }
}
#divInner {
  left: 0;
  position: sticky;
}

#divOuter {
  width: 394px;
  overflow: hidden;
}
@media (max-width: 768px) {
  #divOuter {
    width: 290px;
  }
}
.ChangePassCard {
  width: 32%;
}
@media (max-width: 768px) {
  .ChangePassCard {
    width: 72%;
  }
}

/* Tablet */
@media (max-width: 768px) {
  .SideDesktopView {
    display: none;
  }
}

@media (max-width: 768px) {
  .asideDesktopView {
    display: none;
  }
}

/* Desktop */
@media (min-width: 769px) {
  .asideTabView {
    display: none;
  }
}

/* Media Query for tab */

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
}
@media only screen and (min-width: 768px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
}
@media (min-width: 1440px) {
  .col-SL-12 {
    width: 100%;
  }
}
@media only screen and (min-width: 1840px) {
  .col-MDD-6 {
    width: 50%;
  }
}

.balanceStyle {
  font-size: 100%;
  line-height: 1;
}

/* For 1366 Resolution */
@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .balanceStyle {
    font-size: 60%;
    line-height: 1;
  }
}

.accountStyle {
  padding-top: 70px;
  font-size: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .accountStyle {
    padding-top: 45%;
    font-size: 14px;
  }
}

@media only screen and (min-width: 1367px) and (max-width: 1571px) {
  .accountStyle {
    padding-top: 23%;
    font-size: 14px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .divQR {
    width: 197px;
  }
}

.SidebarIcon {
  width: 30px;
  height: 30px;
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .SidebarIcon {
    width: 25px;
    height: 25px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1366px) {
  .col-sm-lap {
    width: 75%;
  }
}
