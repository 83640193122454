.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body {
  background-color: #f8f9fe;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  font-size: 100%;
}

@media only screen and (min-width: 1450px) and (max-width: 1680px) {
  html {
    font-size: 90%;
  }
}

@media only screen and (max-width: 1450px) {
  html {
    font-size: 90%;
  }
  .navWrapper a.navigation__link.active {
    padding-bottom: 0.375rem;
    border-bottom: 0.25rem solid #008046;
  }
}

.navWrapper a.navigation__link {
  text-decoration: none;
  color: white;
  text-transform: capitalize;
  font-size: 1.2rem;
  margin-left: 2.5rem;
  font-weight: 400;
  text-align: left;
  letter-spacing: 0px;
}

.navWrapper a.navigation__link.active {
  padding-bottom: 0.8125rem;
  border-bottom: 0.25rem solid #008046;
}

.navWrapper a.navigation__link:hover {
  background-position: 100%;
  color: #008046;
  transform: translateX(16px);
}

.MuiToolbar-regular {
  min-height: 3rem !important;
}

.radioChecked {
  background-color: '#0080460D' !important;
  border: '1px solid #008046' !important;
}
